<template>
    <div class="statistic">
        <div class="sidebar-container">
            <Sidebar title="系统设置" :data="routeList"></Sidebar>
        </div>
        <div class="statistic-content">
            <div class="tabs-container">
                <div class="menu">
                    <div :class="{ 'active': menuValue === item.value }" class="menu-item" v-for="(item, index) in filterMenuList"
                        :key="index" @click="menuClick(item.value)">{{ item.label }}</div>
                </div>
            </div>
            <div class="content-container">
				<router-view ref="routeCom" :addPath="menuValue" :libName="libName"></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import Sidebar from '@/components/sidebar';
import { traverse } from '@/utils/handle.js';

export default {
    components: {
        Sidebar
    },
    data(){
        return {
            routeList: [],
            currentRouteName: 'system',      //  当前路由
            menuList: [
                {
                    label: '法院领导库',
                    value: ''
                },
                {
                    label: '年轻干部信息库',
                    value: '/cadre'
                },
                {
                    label: '院长梯队库',
                    value: '/dean'
                },
            ],
            menuValue: '',
        }
    },
    created(){
        this.getRouteList();
    },
    computed: {
        permissions(){
            return this.$store.getters.permissions;
        },
        filterMenuList(){
            const res = [];
            this.$route.meta.url.split(',').forEach((url,index) => {
                if(this.permissions.includes(url)){
                    res.push(this.menuList[index]);
                }
            })
            return res;
        },
		libName() {
			const lib = this.menuList.find(item => item.value === this.menuValue)
			return lib?.label
		}
    },
    watch: {
		$route: {
            handler(route){
				const { menuValue } =  this.$store.state.status
				
				const found = this.filterMenuList.some(item => item.value === menuValue)
				if(found) {
					this.menuClick(menuValue)
				} else {
					this.menuClick(this.filterMenuList[0] && this.filterMenuList[0].value || '');
				}
            },
            immediate: true
        }
	},
    methods: {
        getRouteList(){
            // console.log(this.$router);
            traverse(this.$router.options.routes,route => {
                if(route.name === this.currentRouteName){
                    // console.log(route);
                    this.routeList = route.children;
                }
            })
        },
        menuClick(val){
			this.$store.commit('status/SET_MENUVLAUE', val)
            this.menuValue = val;
            this.$route.meta.addPath = val;         //  设置addPath,在service拦截器调用
            this.$refs.routeCom && this.$refs.routeCom.getTableData();
        }
    }
}
</script>

<style lang="less" scoped>
.statistic{
    width: 100%;
    display: flex;
    .sidebar-container{
        width: 250px;
        margin-right: 10px;
        padding: 2px;
    }
    .statistic-content{
        width: calc(100% - 250px);
        padding: 16px 20px 0 0;
        box-sizing: border-box;
        overflow: hidden;
        .tabs-container{
            height: 36px;
            margin-bottom: 16px;
            .menu {
                display: flex;
                align-items: center;
                .menu-item {
                    padding: 0 12px;
                    height: 32px;
                    line-height: 30px;
                    text-align: center;
                    border: 1px solid #CCD2E0;
                    background-color: #F5F5F5;
                    border-radius: 4px;
                    margin-right: 12px;
                    box-sizing: border-box;
                    cursor: pointer;
                }

                .menu-item.active {
                    border-color: #008B61;
                    color: #008B61;
                    background-color: #fff;
                }
            }
        }
        .content-container{

            > div{
                background-color: #fff;
                width: 100%;
                height: 100%;
                padding: 20px;
                box-sizing: border-box;
            }
        }
        
        
        
    }
}
</style>